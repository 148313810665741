import React from 'react'
import Header from '../components/Header'
import { SigninCard } from '../components/SigninCard'

const Signin = () => {
    return (
        <div className='page'>
            <Header />
            <div className='registeration_part'>
                <SigninCard />
            </div>
        </div>
    )
}

export default Signin