import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Alert } from "@mui/material";
import { motion } from "framer-motion";


export const StripeForm = ({ changeTab, selectedTab, changeId, changeAddress }) => {
    const [address, setAddress] = useState('')
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)

    const stripe = useStripe();
    const elements = useElements();


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedTab !== 2) setOpen(true)
        else {
            setLoading(true)
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
            });

            if (!error) {
                setLoading(false)
                setSuccess(paymentMethod)
                changeId(paymentMethod.id)
                changeAddress(address)
                changeTab(3)
            } else {
                setLoading(false)
                setError(error.message);
            }
        }
    };

    return (
        <motion.div initial={{ opacity: 0, scale: 0 }}
            animate={{
                opacity: 1,
                scale: 1,
                transition: {
                    type: "keyframes",
                    duration: 1,
                    bounce: 1,
                    delay: 0.1,
                },
            }
            }>
            <form onSubmit={handleSubmit} className='signup_form'>
                {open ? <Alert variant="filled" severity="error" onClose={() => { setOpen(false) }}>Select Plan First</Alert> : <></>}
                {success !== null ? <Alert variant="filled" severity="success" onClose={() => { setSuccess(null) }}>Card Added Successfully</Alert> : <></>}
                {error !== null ? <Alert variant="filled" severity="error" onClose={() => { setError(null) }}>{error}</Alert> : <></>}
                <h4>Enter Info</h4>
                <CardElement />
                <input className="input_address" name='address' type='address' placeholder='Billing Address' value={address} required={true} onChange={(e) => setAddress(e.target.value)} />
                <button className='submit_btn' disabled={loading ? true : false}>{!loading ? 'Save' : 'Loading'}</button>
            </form>
        </motion.div>
    );
};
export default StripeForm