import React, { useEffect } from 'react'
import Header from '../components/Header'
import SinglePlanCard from '../components/SinglePlanCard'
import Plans from '../utility/Plans'

import "../css/billing.css"
import { useSelector } from 'react-redux'

const Billing = () => {
    const { loading, isAuthenticated, user } = useSelector(
        (state) => state.userProfile
    );

    useEffect(() => {
        if (loading !== undefined) {
            if (!loading) {
                if (!isAuthenticated) window.location.replace("/signin");
            }
        }
    }, [isAuthenticated, loading]);
    return (
        <div className="page">
            <Header />
            {
                loading !== undefined && !loading ?
                    <>
                        <div className='show_info'>
                            <p>Hey <span>{user.email}</span> Select a plan from these and proceed further</p>
                        </div>
                        <div className='show_plans_container'>
                            {
                                Plans.map(plan => {
                                    return <SinglePlanCard plan={plan} userPlan = {user?.plan} key = {plan.title}/>
                                })
                            }
                        </div>
                    </> : <div className="main_loader">
                        <div className="loader2"> </div>
                    </div>
            }
        </div>
    )
}

export default Billing