import { Alert } from '@mui/material';
import axios from 'axios';
import { motion } from 'framer-motion';
import React from 'react'
import { useState } from 'react';
import box from "../assets/list.png";
import { BASE } from '../redux/constants';
import { useStripe } from "@stripe/react-stripe-js";

var info = {
    Business: {
        words: "20K Words Included",
        contentTypes: "3 different content types",
        settings: "Advanced Settings",
        dashboard: "Intuitive Dashboard",
        content: "Unique and Better Content",
        specific: "",
    },
    Gold: {
        words: "100K Words Included",
        contentTypes: "5 different content types",
        settings: "Advanced Settings",
        dashboard: "Intuitive Dashboard",
        content: "Unique and Better Content",
        specific: "",
    },
    Enterprise: {
        specific: "Need a solution perfectly catered for your niche",
    },
};
const config = {
    headers: { "content-Type": "application/json" },
    withCredentials: true,
}
const PreviewBilling = ({ selectedPlan, selectedTab, paymentId, selectedAddress }) => {
    let title = selectedPlan.label
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const stripe = useStripe();

    const BASE_URL = BASE
    const submitForm = async () => {
        if (selectedTab === 3) {
            console.log(paymentId, selectedAddress)
            if (!paymentId || !selectedAddress) window.alert("Select address and verify payment")
            try {
                setLoading(true)
                const data = {
                    selectedPlan, id: paymentId, address: selectedAddress
                }
                const response = await axios.post(
                    BASE_URL + "stripe/subscribe",
                    data,
                    config
                );

                console.log("Stripe 35 | data", response.data.success);
                if (response.data.success) {
                    setLoading(false)
                    window.location.replace('/dashboard')
                } else {
                    const confirm = stripe.confirmCardPayment(response.data.id)
                    if (!confirm) {
                        setLoading(false)
                        window.alert("CheckoutForm.js 28 | confirm payment");
                    }
                }
            } catch (error) {
                setLoading(false)
                window.alert("CheckoutForm.js 28 | ", error);
            }
        } else setOpen(true)
    }

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
                opacity: 1,
                scale: 1,
                transition: {
                    type: "keyframes",
                    duration: 1,
                    bounce: 1,
                    delay: 0.1,
                },
            }
            } className='signup_form'>
            {open ? <Alert variant="filled" severity="error" onClose={() => { setOpen(false) }}>Enter Billing Information First</Alert> : <></>}
            <h4>Preview & Confirm</h4>
            {title !== "Enterprise" ?
                <div className="price_features_list selected_plan_payment">
                    <h4>{title}</h4>
                    <div>
                        <img src={box} alt={title} />
                        <p>{info[title].words}</p>
                    </div>
                    <div>
                        <img src={box} alt={title} />
                        <p>{info[title].contentTypes}</p>
                    </div>
                    <div>
                        <img src={box} alt={title} />
                        <p>{info[title].settings}</p>
                    </div>
                    <div>
                        <img src={box} alt={title} />
                        <p>{info[title].dashboard}</p>
                    </div>
                    <div>
                        <img src={box} alt={title} />
                        <p>{info[title].content}</p>
                    </div>
                </div> :
                <div className="price_features_list selected_plan_payment">
                    <h4>{title}</h4>
                    <div>
                        <p>{info[title].specific}</p>
                    </div>
                </div>}
            <button className='submit_btn' disabled={loading ? true : false} onClick={submitForm}>{!loading ? 'Pay Now' : 'Loading'}</button>
        </motion.div>
    )
}

export default PreviewBilling