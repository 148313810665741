import Select from 'react-select'
import React, { useState } from 'react'
import { Alert } from '@mui/material'
import { motion } from 'framer-motion'

const SelectPlan = ({ changePlan, changeTab, selectedTab }) => {
    const [open, setOpen] = useState(false)
    const options = [
        { value: 799.9, label: 'Business' },
        { value: 1600, label: 'Gold' },
        { value: 5200, label: 'Enterprise' }
    ]
    const [plan, selectedPlan] = useState(options[0])

    const selectTabFun = () => {
        changePlan(plan)
        setOpen(true)
        changeTab(2)
    }
    return (
        <motion.div
        initial={{ opacity: 0, scale: 0 }}
            animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                        type: "keyframes",
                        duration: 1,
                        bounce: 1,
                        delay: 0.1,
                    },
                }
            }
        className='signup_form'>
            {open ? <Alert variant="filled" severity="success" onClose={() => { setOpen(false) }}>Plan Successfully Selected</Alert> : <></>}
            <h4>Select Plan</h4>
            <div className="select_option_price">
                <Select options={options} defaultValue={options[0]} onChange={(e) => selectedPlan(e)} />
            </div>
            <button className='submit_btn' onClick={selectTabFun}>Select Plan</button>
        </motion.div>
    )
}

export default SelectPlan

/*

import Select from 'react-select'
import React, { useState } from 'react'
import { Alert } from '@mui/material'
import { motion } from 'framer-motion'

const SelectPlan = () => {

    const [open, setOpen] = useState(false)
    const [buttonId, setButtonId] = useState("buy_btn_1MlF6oHhSi7BYQWSk8dNVuZi")

    const publish_key = "pk_live_51JlpfKHhSi7BYQWSe5nUsIGlJIYb0PzJnn08QhZqZ7CHdGXlNK0vHDmZsx1lymDQ6YsyFnXChLeWKyv9HYqJVANQ00yUkYIpWM"

    const options = [
        { value: 1600, label: 'Business', bId: "buy_btn_1MlEibHhSi7BYQWS9bkq4oNk" },
        { value: 4200, label: 'Gold', bId: "buy_btn_1MlF6oHhSi7BYQWSk8dNVuZi" },
        { value: 10000, label: 'Enterprise', bId: "buy_btn_1MlFFoHhSi7BYQWSIBRmXpnZ" }
    ]
    const [plan, selectedPlan] = useState(options[0])

    const changePlan = (e)=>{
        selectedPlan(e)
        e.label === "Business" ? setButtonId("buy_btn_1MlEibHhSi7BYQWS9bkq4oNk") :
        e.label === "Gold" ? setButtonId("buy_btn_1MlF6oHhSi7BYQWSk8dNVuZi") :
        setButtonId("buy_btn_1MlFFoHhSi7BYQWSIBRmXpnZ")

        console.log(buttonId)
    }
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
                opacity: 1,
                scale: 1,
                transition: {
                    type: "keyframes",
                    duration: 1,
                    bounce: 1,
                    delay: 0.1,
                },
            }
            }
            className='signup_form p_10'>
            {open ? <Alert variant="filled" severity="success" onClose={() => { setOpen(false) }}>Plan Successfully Selected</Alert> : <></>}
            <h4>Select Plan</h4>
            <div className="select_option_price">
                <Select options={options} defaultValue={options[0]} onChange={(e) => changePlan(e)} />
            </div>
            {plan.label === "Business" && <stripe-buy-button
                buy-button-id={options[0].bId}
                publishable-key={publish_key}
            ></stripe-buy-button>}
            {plan.label === "Gold" && <stripe-buy-button
                buy-button-id={options[1].bId}
                publishable-key={publish_key}
            ></stripe-buy-button>}
            {plan.label === "Enterprise" && <stripe-buy-button
                buy-button-id={options[2].bId}
                publishable-key={publish_key}
            ></stripe-buy-button>}
            <div id="paypal-button-container-P-9T978316AH225033NMQILR2A"></div>
            // {/* <button className='submit_btn' onClick={selectTabFun}>Select Plan</button> */

//             </motion.div>
//             )
//         }
        
//         export default SelectPlan

// */