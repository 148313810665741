import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import {
  getContent,
  LoginUser,
  LogoutUser,
  RegisterNewUser,
  UserProfile,
  UserProfileUpdate,
  UsernewPlan,
  fetchUsers,
  deleteUser,
  updateUserPlan,
} from "./reducers"

const middelWare = [thunk]
const RootReducer = combineReducers({
  registeredUser: RegisterNewUser,
  loggedinUser: LoginUser,
  userProfile: UserProfile,
  UserProfileUpdate: UserProfileUpdate,
  UserPlan: UsernewPlan,
  content: getContent,
  logoutUser: LogoutUser,
  fetchUsers: fetchUsers,
  deleteUser: deleteUser,
  updateUserPlan: updateUserPlan,
})
const Store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(...middelWare))
)
export default Store
