import visaImage from "../assets/visa-card-logo-9.png"
import mastercardImage from "../assets/MasterCard_logo.png"
import googlePayImage from "../assets/google-pay-logo.png"
import applePayImage from "../assets/apple-pay-w.png"

import paypalImage from "../assets/paypal.png"
import { PayPalButtons } from "@paypal/react-paypal-js"
import axios from "axios"
import { BASE } from "../redux/constants"
import { useEffect } from "react"

const Subscription = ({ plan, user }) => {
  const options = [
    { label: 'Business' },
    { label: 'Gold' },
    { label: 'Enterprise' }
  ]

  let selectedPlan = options.filter((p) => { return p.label === plan?.title })
  let buttonId = returnBID(selectedPlan[0]?.label)
  let planId = returnPlanId(selectedPlan[0]?.label)

  function returnBID(planName) {
    if (planName === "Business") return process.env.REACT_APP_BUSINESS_BUTTON_ID
    else if (planName === "Gold") return process.env.REACT_APP_GOLD_BUTTON_ID
    else if (planName === "Enterprise") return process.env.REACT_APP_ENTERPRISE_BUTTON_ID
  }

  function returnPlanId(planName) {
    if (planName === "Business") return process.env.REACT_APP_BUSINESS_PLAN_ID
    else if (planName === "Gold") return process.env.REACT_APP_GOLD_PLAN_ID
    else if (planName === "Enterprise") return process.env.REACT_APP_ENTERPRISE_PLAN_ID
  }

  useEffect(()=>{
    window.dataLayer.push({
      event: 'purchase',
      eventProps: {
          label: selectedPlan[0].label,
          action: "subscription_page_viewed",
      }
    });
  }, [selectedPlan])


  return (
    <div className='checkout_page_subscription'>
      <div className='pay_with_stripe'>
        <div className="stripe_images">
          <img src={visaImage} alt="pay adley.ai with stripe" />
          <img src={mastercardImage} alt="pay adley.ai with stripe" />
          <img src={googlePayImage} alt="pay adley.ai with stripe" />
          <img src={applePayImage} alt="pay adley.ai with stripe" />
        </div>
        <div className="stripe_button">
          <stripe-buy-button
            buy-button-id={buttonId}
            publishable-key={process.env.REACT_APP_STRIPE_PK}
          ></stripe-buy-button>
        </div>
      </div>
      {/* <hr /> */}
      {/* <div className='pay_with_paypal pay_with_stripe'>
        <div className="stripe_image">
          <img src={paypalImage} alt="pay adley.ai with paypal" />
        </div>
        <div className="stripe_button">
          <PayPalButtons
            disabled={false}
            onApprove={async function (data, actions) {
              const config = {
                headers: { "content-Type": "application/json" },
                withCredentials: true,
              }
              let subscriptionData = { subscriptionId: data.subscriptionID, email: user?.email, plan: selectedPlan[0].label }
              try {
                let result = await axios.post(BASE + "paypal_webhook", subscriptionData, config)
                if (result?.data?.success === true) {
                  window.alert("plan is successfully added")
                }
              } catch (err) {
                console.log(err)
                window.alert(err?.response?.data?.message)
              }
            }}
            onClick={async () => {
              console.log("Event triggered to click")
            }}
            onCancel={() => {
              console.log("cancelled")
            }}
            createSubscription={async function (data, actions) {
              return actions.subscription.create({
                plan_id: planId
              });
            }
            }
          />
        </div>
      </div> */}
    </div>
  )
}

export default Subscription


// business
// buy_btn_1MncJ8Eu5LW6yP4JbpJH13Cb business
// buy_btn_1MncN8Eu5LW6yP4Jf8R6TfeT gold
// buy_btn_1MncPHEu5LW6yP4Jj3okpCxy enterprise