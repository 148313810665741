import Select from 'react-select'

const PromptBar = ({ setLanguage, audience, setAudience, result, setResult, keywords, setKeywords, setCreativity, setToneVoice}) => {
    const languages = [
        { label: "English", value: "English" },
        { label: "Urdu", value: "Urdu" },
        { label: "Spanish", value: "Spanish" },
        { label: "Chinese", value: "Chinese" }
    ];
    const toneVoices = [
        { value: "Neutral", label: "Neutral" },
        { value: "Passive", label: "Passive" },
        { value: "Positive", label: "Positive" },
        { value: "Negative", label: "Negative" },
        { value: "Sarcastic", label: "Sarcastic" }
    ];
    const CreativityOptions = [
        { label: "Low", value: "Low" },
        { label: "Medium", value: "Medium" },
        { label: "High", value: "High" }
    ];

    return (
        <div className="prompt_bar_additional">
            <div className="select_language">
                <h4>Select Language</h4>
                <Select options={languages} defaultValue={languages[0]} onChange={(e) => setLanguage(e.value)} />
            </div>
            <div className="select_options">
                <div className='input_single'>
                    <h4>Audience</h4>
                    <input value={audience} placeholder={'Enter your target audience here. Include age, gender, location, interests, and other relevant details.'} onChange={(e) => setAudience(e.target.value)} />
                </div>

            </div>
            <div className="select_keywords">
                <h4>Keywords </h4>
                <input value={keywords} placeholder={'Enter SEO keywords related to your content. These are the words or phrases that people might use when searching for content like yours. Please separate keywords with commas.'} onChange={(e) => setKeywords(e.target.value)} />
            </div>
            <div className="select_options">
                <div className='select_option_single'>
                    <h4>Choose Creativity</h4>
                    <Select options={CreativityOptions} defaultValue={CreativityOptions[0]} onChange={(e) => setCreativity(e.value)} />
                </div>
                <div className='select_option_single ml_5'>
                    <h4>Select Tone Voice</h4>
                    <Select options={toneVoices} defaultValue={toneVoices[0]} onChange={(e) => setToneVoice(e.value)} />
                </div>
            </div>
        </div>
    )
}

export default PromptBar