import { Alert } from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useState } from "react";
import Header from "../components/Header";
import { BASE } from "../redux/constants";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null)

  const submitData = async (e) => {
    e.preventDefault();
    await forgotPassword();
  };
  async function forgotPassword() {
    setLoading(true);
    let LINK = BASE + `user/reset/request`;
    const config = {
      headers: { "content-Type": "application/json" },
      withCredentials: true,
    };
    const data = {
      gmail: email,
    };
    try {
      let res = await axios.post(LINK, data, config);
      setLoading(false);
      setOpens(true)
      setSuccess("Email is sent check email to reset password")
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setError("invalid gmail");
    }
  }
  return (
    <div className="page">
      <Header />
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: {
            type: "keyframes",
            duration: 1,
            bounce: 1,
            delay: 0.1,
          },
        }
        }
        className="registeration_part">
        <div className="registeration_card">
          {error && open && (
            <Alert
              variant="filled"
              severity="error"
              onClose={() => {
                setOpen(false);
              }}
            >
              {error}
            </Alert>
          )}
          {success && opens && (
            <Alert
              variant="filled"
              severity="success"
              onClose={() => {
                setOpens(false);
              }}
            >
              {success}
            </Alert>
          )}
          <h3>Forget Password</h3>
          <form className="signup_form" onSubmit={(e) => submitData(e)}>
            <input
              type="email"
              placeholder="Email"
              required={true}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              className="submit_btn fency_btn"
              disabled={loading ? true : false}
            >
              {!loading ? "Forget Password" : "loading"}
            </button>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default ForgetPassword;
