import '../css/register.css'
import Header from '../components/Header'
import SignupCard from '../components/SignupCard'

const Signup = () => {
    return (
        <div className='page'>
            <Header />
            <div className='registeration_part'>
                <SignupCard />
            </div>
        </div>
    )
}

export default Signup