import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import User from '../../components/Admin/User'
import Header from "../../components/Header"
import { fetchUsersAction, userProfileAction } from '../../redux/actions'
const Dashboard = () => {
  // check if user is admin
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [userName, setUserName] = useState("")
  const [page, setPage] = useState(1)
  const [plan, setPlan] = useState("")

  const [name, setName] = useState("")

  useEffect(() => {
    dispatch(fetchUsersAction(userName, page, plan))
  }, [userName, page, plan, dispatch])

  const { isAuthenticated, loading, user } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (loading !== undefined) {
      if (!loading) {
        if (!isAuthenticated) navigate("/signin");
        if (user.type !== "admin") navigate("/dashboard");
      }
    }

  }, [isAuthenticated, navigate, loading, user.plan]);

  console.log(user)
  const { loading: loadingUsers, users: fetchedUsers, error: fetchedUsersError, records } = useSelector((state) => state.fetchUsers)
  const { loading: loadingDeleteUser, error: deleteError } = useSelector(state => state.deleteUser)
  const { loading: loadingUpdateUser, error: updateError } = useSelector(state => state.updateUserPlan)


  const goForward = () => {
    if (!loadingUsers && !fetchedUsersError) {
      if (records >= 10) {
        setPage(page => page + 1)
      }
    }
  }
  const goBack = () => {
    if (!loadingUsers && !fetchedUsersError) {
      if (page > 1) {
        setPage(page => page - 1)
      }
    }
  }
  const searchByPlan = (p) => {
    setPlan(p)
  }
  return (
    <div className="page">
      <Header />
      {
        !loading ? <>
          <div className='users_search_bar search_bar'>
            <input placeholder='Enter gmail' onChange={(e) => setName(e.target.value)} />
            <button className='fency_btn submit_btn' onClick={() => setUserName(name)}>Search</button>
          </div>
          {
            loadingUsers !== undefined && loadingUsers !== null && (
              !loadingUsers && !loadingDeleteUser && !loadingUpdateUser ? (records > 0 && !fetchedUsersError) ?
                <div className='users_section'>
                  {
                    fetchedUsers?.map((user, key) => {
                      return <User user={user} key={key} index={key} />
                    })
                  }
                </div> : fetchedUsersError ? <div className='no_user_found'><p>You are not admin</p></div> :
                  <div className='no_user_found'><p>no record found</p></div> :
                <div className="main_loader">
                  <div className="loader2"> </div>
                </div>
            )
          }
          <div className='page_navigation'>
            <p onClick={goBack}>previous</p>
            <p onClick={goForward}>next</p>
          </div>
        </> : <div className="main_loader">
          <div className="loader2"> </div>
        </div>
      }

    </div>
  )
}

export default Dashboard