import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { profileUserAction, planAction, config } from "../redux/actions";
import { useInView } from "react-intersection-observer";
import { useAlert } from "react-alert";
import { Alert, LinearProgress } from "@mui/material";
import 'react-phone-number-input/style.css'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE } from "../redux/constants";

const PlanCard = ({ finaluser, setFinalUser, profileUpdate, loading, writtenWords }) => {
    const { ref, inView } = useInView({ threshold: 0.3 });
    const dispatch = useDispatch()
    // const alert = useAlert()
    // const [id, setId] = useState(finaluser._id)

    // const [written, setWritten] = useState()
    const [open, setOpen] = useState(false)
    const [finalplans, setPlans] = useState([])
    const [loadingSub, setLoadingSub] = useState(false)
    const [timeLeft, setTimeLeft] = useState("")
    // const [e, setE] = useState('')

    // const getPlans = () => {
    //     dispatch(planAction());
    // }
    const { plans } = useSelector(state => state.UserPlan)
    // const { user } = useSelector(
    //     (state) => state.userProfile
    // );
    useEffect(() => {
        dispatch(planAction())
    }, [dispatch])

    // useEffect(() => {
    //     setWritten(writtenWords)
    // }, [writtenWords])
    useEffect(() => {
        setPlans(plans)
    }, [plans])

    useEffect(() => {
        fetchSubscriptionDetail()
    }, [])

    const fetchSubscriptionDetail = async () => {
        setLoadingSub(true)
        try {
            let res = await axios.get(BASE + "user/subscription-detail", config)
            setTimeLeft(res.data.timeLeft)
        } catch (e) {
            console.log(e)
        } finally{
            setLoadingSub(false)
        }
    }

    const cancelSubscription = async () => {
        setLoadingSub(true)
        try {
            let res = await axios.get(BASE + "user/cancel-subscription", config)
            console.log(res)
        } catch (e) {
            console.log(e)
        } finally{
            setLoadingSub(false)
            window.location.replace("/")
        }
    }
    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, scale: 0 }}
            animate={
                inView && {
                    opacity: 1,
                    scale: 1,
                    transition: {
                        type: "keyframes",
                        duration: 1,
                        bounce: 1,
                        delay: 0.1,
                    },
                }
            }
            className="profile_card show_plan"
        >
            {/* {open && !loading && e ? <Alert variant="filled" severity="error" onClose={() => { setOpen(false) }}>{e}</Alert> : <></>} */}
            {/* <h3>Your Plan</h3>
            <div className='plan-form'>
                {finalplans?.filter(plan => plan.plan_name === finaluser.plan).map((plan, sl) => (
                    <div className="plan-package" key={sl}>
                        <div className="plan_card_left">
                            <h4>ADLEY.AI</h4>
                            <p className="small_p">Your plan is</p>
                            <p className="f_large">{plan.plan_name}</p>
                            <div className="plan_card_info">
                                <div className="plan_card_info_combo">
                                    <CheckBoxOutlinedIcon /><p>{plan.allowed_words + " "} words</p>
                                </div>
                                <div className="plan_card_info_combo">
                                    <CheckBoxOutlinedIcon />
                                    <p>24/7 support</p>
                                </div>
                                <div className="plan_card_info_combo">
                                    <CheckBoxOutlinedIcon />
                                    <p>SEO optimized content</p>
                                </div>
                                <div className="count_words_progress">
                                    <p>written words: {plan.allowed_words - writtenWords} </p><LinearProgress variant="determinate" color="success" value={((plan?.allowed_words - writtenWords) / (plan?.allowed_words)) * 100} />
                                </div>
                            </div>
                        </div>
                        <div className="plan_card_right">
                            <Link to='/billing' ><button type="button" className="plan_upgrade_btn">Upgrade</button></Link>
                            <p>{plan.price / 100} $/month</p>
                        </div>
                    </div>
                ))}
            </div> */}
            {/* <div className="add_words">
                <h3> Add more words</h3>
            </div> */}
            <h3>Subscription details</h3>
            <div className='plan-form'>
                {finalplans?.filter(plan => plan.plan_name === finaluser.plan).map((plan, sl) => (
                    <div className="plan-package" key={sl}>
                        <div className="plan_card_left">
                            <h4>ADLEY.AI</h4>
                            <div className="plan_card_info">
                                <div className="plan_card_info_combo">
                                    {/* <p>Your subscription will renew in <br /> <span style={{ marginTop: "10 !important" }}>{loadingSub ? "Loading" : timeLeft }</span></p> */}
                                    <p>To change or cancel your plan, please email <a href="mailto:hello@adley.ai">hello@adley.ai</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="plan_card_right">
                            {/* <button type="button" className="plan_upgrade_btn" onClick={cancelSubscription} >{loadingSub ? "Loading" : "Cancel"}</button> */}
                        </div>
                    </div>
                ))}
            </div>
        </motion.div>
    );
};

export default PlanCard;
