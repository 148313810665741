import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { registerUserAction } from "../redux/actions";
import { useInView } from "react-intersection-observer";
import { useAlert } from "react-alert";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import Reaptcha from 'reaptcha';

const SignupCard = () => {
    const { ref, inView } = useInView({ threshold: 0.3 });
    const dispatch = useDispatch();
    const alert = useAlert();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setCPassword] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');

    const [open, setOpen] = useState(false);
    const [vFirstName, setVFirstName] = useState('');
    const [vLastName, setVLastName] = useState('');
    const [vPassword, setVPassword] = useState('');
    const [vConfirmPassword, setVCPassword] = useState('');
    const [vEmail, setVEmail] = useState('');
    const [vContact, setVContact] = useState('');

    const [e, setE] = useState('');

    const { isAuthenticated, loading, error } = useSelector(state => state.registeredUser)
    useEffect(() => {
        if (!loading && isAuthenticated) {
            window.dataLayer.push({
                event: 'free_account_created'
              });
            window.location.replace('/freetrial')
        }
        if (!loading && error) {
            setOpen(true)
            if (typeof error === "string") {
                setE(error)
            }
            else {
                setE('validation error')
                for (let err in error) {
                    alert.error(error[err])
                }
                //dispatch(clearErrors())
            }
        }
    }, [loading, isAuthenticated, error, alert])

    const validateFName = (e) => {
        setFirstName(e.target.value)
        if (e.target.value.length < 2) setVFirstName('first name must be above 2 letters')
        else setVFirstName('')
    }

    const validateLName = (e) => {
        setLastName(e.target.value)
        if (e.target.value.length < 2) setVLastName('last name must be above 2 letters')
        else setVLastName('')
    }
    const ValidateEmail = (e) => {
        setEmail(e.target.value)
        if (!(/[^\s]*@[a-z0-9.-]*/i.test(e.target.value))) {
            setVEmail('enter valid email')
        }
        else setVEmail('')
    }
    const validateContact = (e) => {
        setContact(e.target.value)
        if (e.target.value.length < 6) setVContact('enter valid contact')
        else setVContact('')
    }
    const validatePassword = (e) => {
        if (e.target.value.length < 6) setVPassword('password must be above 6 letters')
        else setVPassword('')
        setPassword(e.target.value)
    }
    const validateCPassword = (e) => {
        setCPassword(e.target.value)
        if (password !== e.target.value) setVCPassword('passwords must be same')
        else setVCPassword('')

    }
    const submitData = (e) => {
        const userData = {
            firstName,
            lastName,
            password,
            email,
            contact
        }
        e.preventDefault();
        if (password !== confirmPassword) {
            setE('passwords are not same')
            setOpen(true)
            return
        }
        dispatch(registerUserAction(userData))
    }
    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, scale: 0 }}
            animate={
                inView && {
                    opacity: 1,
                    scale: 1,
                    transition: {
                        type: "keyframes",
                        duration: 1,
                        bounce: 1,
                        delay: 0.1,
                    },
                }
            }
            className="registeration_card"
        >
            {open && !loading && e ? <Alert variant="filled" severity="error" onClose={() => { setOpen(false) }}>{e}</Alert> : <></>}
            <h3>Sign Up</h3>
            <form className='signup_form' onSubmit={(e) => submitData(e)}>
                <p className='little_error_p'>{vFirstName}</p>
                <input name='fname' type='text' placeholder='First Name' required={true} onChange={(e) => validateFName(e)} />
                <p className='little_error_p'>{vLastName}</p>
                <input name='lname' type='text' placeholder='Last Name' required={true} onChange={(e) => validateLName(e)} />
                <p className='little_error_p'>{vEmail}</p>
                <input name='email' type='email' placeholder='Email' required={true} onChange={(e) => ValidateEmail(e)} />
                <p className='little_error_p'>{vContact}</p>
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    value={contact}
                    onChange={setContact}
                    id="phone_input" />
                {/* <input name='contact' type='number' placeholder='Telephone No' required={true} onChange={(e) => validateContact(e)} /> */}
                <div className='input_combo'>
                    <div>
                        <p className='little_error_p'>{vPassword}</p>
                        <input name='password' type='password' placeholder='Password' required={true} onChange={(e) => validatePassword(e)} />
                    </div>
                    <div>
                        <p className='little_error_p'>{vConfirmPassword}</p>
                        <input name='Cpassword' type='password' placeholder='Confirm Password' required={true} onChange={(e) => validateCPassword(e)} />
                    </div>
                </div>
                <button type="submit" className="submit_btn fency_btn" disabled={loading}>
                    {loading ? "Loading..." : "Continue"}
                </button>
                <div className='para_'>
                    <p>
                        Already member <Link to="/signin">Sign in Now</Link>
                    </p>
                    <p>
                        By signing up, you are agreeing to our
                        <a style={{ color: "#d220ff" }} target='_blank' href = "/terms.html"><span> Terms and Conditions</span> </a>and <a style={{ color: "#d220ff" }} target='_blank' href = "/privacy.html"><span>Privacy Policy</span></a>
                    </p>
                </div>

            </form>
        </motion.div>
    );
};

export default SignupCard;
