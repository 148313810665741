
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./StripeForm";
import PreviewBilling from "./PreviewBilling";
import SelectPlan from "./SelectPlan";


const PUBLIC_KEY = "pk_test_51MDVrZAfjm1p4H1ot6DQdFpA0A4rqYXwwZ5Qx0YaEGYjNQjkPRiVqomH6xc3pjKkKBdco3MaPGzCjQ8zsxdDsfom00lDtPGjMd";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const PaymentForm = () => {
    const [selectedPlan, setSelectedPlan] = useState({ label: 'Business' })
    const [currentTab, setCurrentTab] = useState(1)
    const [paymentId, setPaymentId] = useState()
    const [address, setAddress] = useState()

    const changeTab = (tab) => setCurrentTab(tab)
    const changePlan = (plan) => setSelectedPlan(plan)
    const changeAddress = (add) => setAddress(add)
    const changeId = (id) => setPaymentId(id)

    return (
        <>
            <Elements stripe={stripeTestPromise}>
                <div className='registeration_card payment_card'>
                    <SelectPlan changePlan={changePlan} selectedPlan={selectedPlan} changeTab={changeTab} selectedTab={currentTab} />
                </div>
                <div className='registeration_card payment_card'>
                    <StripeForm selectedPlan={selectedPlan} changeTab={changeTab} selectedTab={currentTab} changeId={changeId} changeAddress={changeAddress} />
                </div>
                <div className='registeration_card payment_card'>
                    <PreviewBilling selectedPlan={selectedPlan} selectedTab={currentTab} paymentId={paymentId} selectedAddress={address} />
                </div>
            </Elements>
        </>
    );
};

export default PaymentForm;