import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PLAN_FAIL,
  USER_PLAN_REQUEST,
  USER_PLAN_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  CLEAR_ERRORS,
  WRITE_CONTENT_REQUEST,
  WRITE_CONTENT_SUCCESS,
  WRITE_CONTENT_FAIL,
  BASE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_PLAN_REQUEST,
  UPDATE_USER_PLAN_SUCCESS,
  UPDATE_USER_PLAN_FAIL,
} from "./constants"
import axios from "axios"

export const config = {
  headers: { "content-Type": "application/json" },
  withCredentials: true,
}

const BASE_URL = BASE

export const registerUserAction = (signupData) => async (dispatch) => {
  console.log(signupData)
  try {
    dispatch({ type: USER_REGISTER_REQUEST })
    const res = await axios.post(BASE_URL + "user/register", signupData, config)
    dispatch({ type: USER_REGISTER_SUCCESS, payload: res.data.user })
  } catch (err) {
    dispatch({ type: USER_REGISTER_FAIL, payload: err.response?.data?.message })
  }
}
export const profileUserAction = (userData) => async (dispatch) => {
  console.log(userData)
  try {
    dispatch({ type: USER_PROFILE_UPDATE_REQUEST })
    const res = await axios.post(BASE_URL + "user/profile/update", userData, config)
    dispatch({ type: USER_PROFILE_UPDATE_SUCCESS, payload: res.data.user })
  } catch (err) {
    dispatch({ type: USER_PROFILE_UPDATE_FAIL, payload: err.response?.data?.message })
  }
}

export const planAction = ()=> async (dispatch) => {
  try {
    dispatch({ type: USER_PLAN_REQUEST })
    const res = await axios.get(BASE_URL + "plans", config)
    dispatch({ type: USER_PLAN_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: USER_PLAN_FAIL, payload: err.response?.data?.message })
  }
}

export const loginUserAction = (loginData) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST })
  try {
    const res = await axios.post(BASE_URL + "user/login", loginData, config)
    dispatch({ type: USER_LOGIN_SUCCESS, payload: res.data.user })
  } catch (err) {
    dispatch({ type: USER_LOGIN_FAIL, payload: err.response?.data?.message })
  }
}

export const userProfileAction = ()=> async (dispatch) => {
  dispatch({ type: USER_PROFILE_REQUEST })
  try {
    const res = await axios.get(BASE_URL + "user/profile", config)
    dispatch({ type: USER_PROFILE_SUCCESS, payload: {
      user: res.data.user,
      remainingWords: res.data.remainingWords,
      trialStatus: res.data.trialStatus,
  }})
  } catch (err) {
    dispatch({ type: USER_PROFILE_FAIL, payload: err.response?.data?.message })
  }
}

export const logoutUserAction = async (dispatch) => {
  try {
    dispatch({ type: USER_LOGOUT_REQUEST })
    const res = await axios.get(BASE_URL + "user/logout", config)
    dispatch({ type: USER_LOGOUT_SUCCESS, payload: res.data.user })
  } catch (err) {
    dispatch({ type: USER_LOGOUT_FAIL, payload: err.response?.data?.message })
  }
}

export const getContentAction = (reqData) => async (dispatch) => {
  try {
    console.log(reqData)
    dispatch({ type: WRITE_CONTENT_REQUEST })
    const res = await axios.post(BASE_URL + "article/write", reqData, config)
    dispatch({ type: WRITE_CONTENT_SUCCESS, payload: res.data })
  } catch (err) {
    console.log(err)
    dispatch({
      type: WRITE_CONTENT_FAIL,
      payload: {
        error: err.response?.data?.message,
        status: err.response.status,
      },
    })
  }
}

export const fetchUsersAction = (user = "", page = 1, plan = "")  => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USERS_REQUEST })
    const res = await axios.get(BASE_URL + `admin/users?user=${user}&page=${page}&plan=${plan}`, config)
    dispatch({ type: FETCH_USERS_SUCCESS, payload: res.data })
  } catch (err) {
    console.log(err)
    dispatch({
      type: FETCH_USERS_FAIL,
      payload: {
        error: err.response?.data?.message,
        status: err.response?.status,
      },
    })
  }
}

export const deleteUserAction = (user)  => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST })
    const res = await axios.post(BASE_URL + `admin/delete/user`, user, config)
    dispatch({ type: DELETE_USER_SUCCESS, payload: res.data })
  } catch (err) {
    console.log(err)
    dispatch({
      type: DELETE_USER_FAIL,
      payload: {
        error: err.response?.data?.message,
        status: err.response?.status,
      },
    })
  }
}

export const updateUserPlanAction = (user)  => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_PLAN_REQUEST })
    const res = await axios.post(BASE_URL + `admin/change/plan`, user, config)
    dispatch({ type: UPDATE_USER_PLAN_SUCCESS, payload: res.data })
  } catch (err) {
    console.log(err)
    dispatch({
      type: UPDATE_USER_PLAN_FAIL,
      payload: {
        error: err.response.data.message,
        status: err.response.status,
      },
    })
  }
}


export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS })
}
