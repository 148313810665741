import React from "react";
import { Link } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import pic from "../assets/aidely (2).png";

const Footer = () => {
  return (
    <footer>
      <div className="canvas footer">
        <div className="footer_logo">
          <Link to="/">
            <div className="logo">
              <img src={pic} alt="adley.ai" />
              <p>ADLEY</p>
            </div>
          </Link>
          <p>
            #1 Content writing tool powered by artificial intelligence. Generate original content
            in just seconds, instead of
            hours or days.
          </p>
          
          {/* <p>© Writely AI 2022</p> */}
        </div>
        <div className="footer_links">
          <div className="footer_links_">
            <ul>
              <li>Pricing</li>
              <a href="https://adley.ai/#price_section" rel="noopener noreferrer" target="_blank"><li>Business</li></a>
              <a href="https://adley.ai/#price_section" rel="noopener noreferrer" target="_blank"><li>Gold</li></a>
              <a href="https://adley.ai/#price_section" rel="noopener noreferrer" target="_blank"><li>Enterprise</li></a>
            </ul>
          </div>
          <div className="footer_links_">
            <ul>
              <li>Features</li>
              <a href="https://adley.ai/#features_section" rel="noopener noreferrer" target="_blank"><li>SEO optimized content</li></a>
              <a href="https://adley.ai/#features_section" rel="noopener noreferrer" target="_blank"><li>Boost ad conversions</li></a>
              <a href="https://adley.ai/#features_section" rel="noopener noreferrer" target="_blank"><li> Content marketing</li></a>
            </ul>
          </div>
          <div className="footer_links_">
            <ul>
              <li>Resources</li>
              <a href="https://adley.ai/#business_section" rel="noopener noreferrer" target="_blank"><li>Testimonials</li></a>
              <a href="https://adley.ai/#newsletter_section" rel="noopener noreferrer" target="_blank"><li>Newsletter</li></a>
              <a style={{ color: "#d220ff" }} target='_blank' href = "/privacy.html"><li>Privacy Policy</li></a>
              <a style={{ color: "#d220ff" }} target='_blank' href = "/terms.html"><li>Terms of Services</li></a>
            </ul>
          </div>

        </div>
      </div>
      <div className="canvas footer_end">
        <p>Copyright &copy; 2023 Savage Moves LLC. All Rights Reserved </p>
        <div className="footer_icons">
          <FacebookIcon className="f_icon" />
          <TwitterIcon className="f_icon" />
          <LinkedInIcon className="f_icon" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;