import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { BASE } from "../redux/constants";
import { Alert } from "@mui/material";
import { motion } from "framer-motion";

const ResetPassword = () => {
  const params = useParams();
  const { id, token } = params;
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const submitData = async (e) => {
    e.preventDefault();
    if (password !== cpassword) {
      setOpen(true);
      setError("Passwords must be same");
      return;
    } else await resetPassword();
  };
  async function resetPassword() {
    setLoading(true);
    let LINK = BASE + `user/${id}/reset/${token}`;
    const config = {
      headers: { "content-Type": "application/json" },
      withCredentials: true,
    };
    const data = {
      newPassword: password,
    };
    try {
      let res = await axios.post(LINK, data, config);
      setLoading(false);
      window.location.replace("/Signin")
    } catch (error) {
      setLoading(false);
      setOpen(true)
      setError("Invalid URL or Token expired")
    }
  }
  return (
    <div className="page">
      <Header />
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: {
            type: "keyframes",
            duration: 1,
            bounce: 1,
            delay: 0.1,
          },
        }
        }
        className="registeration_part">
        <div className="registeration_card">
          {error && open && (
            <Alert
              variant="filled"
              severity="error"
              onClose={() => {
                setOpen(false);
              }}
            >
              {error}
            </Alert>
          )}
          <h3>Reset Password</h3>
          <form className="signup_form" onSubmit={(e) => submitData(e)}>
            <input
              type="password"
              placeholder="new password"
              required={true}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="confirm password"
              required={true}
              onChange={(e) => setCPassword(e.target.value)}
            />
            <button
              type="submit"
              className="submit_btn fency_btn"
              disabled={loading ? true : false}
            >
              Reset Password
            </button>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default ResetPassword;
