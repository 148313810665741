import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { profileUserAction, planAction, clearErrors, userProfileAction } from "../redux/actions";
import { useInView } from "react-intersection-observer";
import { useAlert } from "react-alert";
import { Alert } from "@mui/material";

import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
const ProfileCard = ({ finaluser, setFinalUser }) => {
    const { ref, inView } = useInView({ threshold: 0.3 });
    const dispatch = useDispatch()
    const alert = useAlert()
    const [id, setId] = useState(finaluser._id)
    const [firstName, setFirstName] = useState(finaluser.firstName)
    const [lastName, setLastName] = useState(finaluser.lastName)
    const [oldpassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setCPassword] = useState('')
    const [email, setEmail] = useState(finaluser.email)
    const [phone, setPhone] = useState(finaluser.phone)
    const [plan, setPlan] = useState(finaluser.plan)

    const [finalplans, setPlans] = useState([])

    const [open, setOpen] = useState(false)
    const [vFirstName, setVFirstName] = useState('')
    const [vLastName, setVLastName] = useState('')
    const [vPassword, setVPassword] = useState('')
    const [vConfirmPassword, setVCPassword] = useState('')
    const [vEmail, setVEmail] = useState('')
    const [vContact, setVContact] = useState('')
    const [vPlan, setVPlan] = useState('')
    const [updated, setUpdated] = useState(false)

    const [e, setE] = useState('')

    const validateFName = (e) => {
        setFirstName(e.target.value)
        if (e.target.value.length < 2) setVFirstName('first name must be above 4 letters')
        else setVFirstName('')
    }

    const validateLName = (e) => {
        setLastName(e.target.value)
        if (e.target.value.length < 2) setVLastName('last name must be above 4 letters')
        else setVLastName('')
    }
    const ValidateEmail = (e) => {
        setEmail(e.target.value)
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))) {
            setVEmail('enter valid email')
        }
        else setVEmail('')
    }
    const ValidatePlan = (e) => {

        if (!e.target.value) {
            setVPlan('enter plan')
        }
        else setVPlan('')
        setPlan(e.target.value)
    }
    const validateContact = (e) => {
        setPhone(e)
        if (e && e.length < 6) setVContact('enter valid contact')
        else setVContact('')
    }
    const validateOldPassword = (e) => {
        setOldPassword(e.target.value)
    }
    const validatePassword = (e) => {
        if (e.target.value.length < 6) setVPassword('password must be above 6 letters')
        else setVPassword('')
        setPassword(e.target.value)
    }
    const validateCPassword = (e) => {
        setCPassword(e.target.value)
        if (password !== e.target.value) setVCPassword('passwords must be same')
        else setVCPassword('')

    }
    const { plans } = useSelector(state => state.UserPlan)
    const { loading, user, error } = useSelector(state => state.UserProfileUpdate)
    useEffect(() => {
        dispatch(planAction())
    }, [dispatch])

    useEffect(() => {
        setPlans(plans)
    }, [plans])
    useEffect(() => {
        if (!loading && error) {
            setOpen(true)
            if (typeof error === "string") {
                setE(error)
            }
            else {
                setE('validation error')
                for (let err in error) {
                    alert.error(error[err])
                }
                dispatch(clearErrors())
            }
        } else if (!loading) {
            setUpdated(true)
        }
        if (loading !== null && !loading && !error) {
            if (updated && user !== undefined && user !== null) {
                dispatch(userProfileAction)
                setFinalUser(user)
            }
        }
    }, [loading, user, error, alert, dispatch])
    const submitData = (e) => {
        const userData = {
            id,
            firstName,
            lastName,
            // password,
            // oldpassword,
            email,
            // plan,
            phone
        }
        e.preventDefault()
        if (password !== confirmPassword) {
            setE('passwords are not same')
            setOpen(true)
            return
        }
        dispatch(profileUserAction(userData))
    }
    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, scale: 0 }}
            animate={
                inView && {
                    opacity: 1,
                    scale: 1,
                    transition: {
                        type: "keyframes",
                        duration: 1,
                        bounce: 1,
                        delay: 0.1,
                    },
                }
            }
            className="profile_card"
        >
            {open && !loading && e ? <Alert variant="filled" severity="error" onClose={() => { setOpen(false) }}>{e}</Alert> : <></>}
            <h4>Profile</h4>
            <form className='profile_form' onSubmit={(e) => submitData(e)}>
                <p className='little_error_p'>{vFirstName}</p>
                <input name='fname' type='text' defaultValue={finaluser.firstName} placeholder='First Name' required={true} onChange={(e) => validateFName(e)} />
                <input type="hidden" name="id" value={finaluser.id} />
                <p className='little_error_p'>{vLastName}</p>
                <input name='lname' type='text' placeholder='Last Name' defaultValue={finaluser.lastName} required={true} onChange={(e) => validateLName(e)} />
                <p className='little_error_p'>{vEmail}</p>
                <input name='email' type='email' placeholder='Email' value={finaluser.email} required={true} onChange={(e) => ValidateEmail(e)} />
                <p className='little_error_p'>{vContact}</p>
                <PhoneInput

                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="CA"
                    value={finaluser.phone}
                    onChange={validateContact}
                    id="phone_input" />
                {/* <input name='contact' type='number' placeholder='Telephone No' required={true} onChange={(e) => validateContact(e)} />
                <p className='little_error_p'>{vPlan}</p>
                <select className="" defaultValue={plan} onChange={(e) => ValidatePlan(e)}>
                    {finalplans?.map((plan, sl) => (
                        <option value={plan.id} selected={plan.plan_name === finaluser.plan} key = {sl}>{plan.plan_name}</option>
                    ))}
                </select> */}
                {/* <input name='password' type='password' placeholder='Old Password' required={true} onChange={(e) => validateOldPassword(e)} />
                <p className='little_error_p'>{vPassword}</p>
                <input name='password' type='password' placeholder='New Password' required={true} onChange={(e) => validatePassword(e)} />
                <p className='little_error_p'>{vConfirmPassword}</p>
                <input name='Cpassword' type='password' placeholder='Confirm Password' required={true} onChange={(e) => validateCPassword(e)} /> */}

                <button type="submit" className="submit_btn fency_btn" disabled={loading ? true : false}>
                    {loading ? "loading" : "Update"}
                </button>

            </form>
        </motion.div>
    );
};

export default ProfileCard;
