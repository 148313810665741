import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE } from '../redux/constants'
import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import '../css/payment.css'
import '../css/verification.css'

const Verification = () => {
    const params = useParams()
    const {userId, token} = params;
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    async function verifyEmail() {
        setLoading(true)
        let LINK = BASE + `user/${userId}/verify/${token}`
        try {
          let res = await axios.get(LINK)
          setLoading(false)
          console.log(res)
        } catch (error) {
          setLoading(false)
          setError("Invalid URL or Token expired")
        }
      }
    useEffect(() => {
        verifyEmail()
    }, [])
    
    return (
        <div className='page'>
            <Header />
            <h3 className='pay_now'>Email Verification</h3>
            <div className='verification_error'>
                {
                    loading !== undefined? loading ? <p>Loading ...</p> : error ? <p>{error}</p> : <p> Email Verified Successfully. Return to main page</p> : <p></p>
                }
            </div>
        </div>
    )
}

export default Verification