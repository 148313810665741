// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  /* font-family: \"Open Sans\", Arial; */\n  /* font-family: 'Roboto', sans-serif; */\n  margin: 0px;\n  padding: 0px;\n  box-sizing: border-box;\n  font-family: 'Inter', sans-serif !important;\n  scroll-behavior: smooth !important;\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,qCAAqC;EACrC,uCAAuC;EACvC,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,2CAA2C;EAC3C,kCAAkC;AACpC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@400;500;700;900&display=swap');\n\nbody {\n  /* font-family: \"Open Sans\", Arial; */\n  /* font-family: 'Roboto', sans-serif; */\n  margin: 0px;\n  padding: 0px;\n  box-sizing: border-box;\n  font-family: 'Inter', sans-serif !important;\n  scroll-behavior: smooth !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
