var Plans = [
    {
        title: "Business",
        price: 799,
        words: "20K Words Included",
        contentTypes: "3 different content types",
        settings: "Advanced Settings",
        dashboard: "Intuitive Dashboard",
        content: "Unique and Better Content",
        specific: "",
        whatsapp: "Get Adley on  WhatsApp ",
    },
    {
        title: "Gold",
        price: 1600,
        words: "100K Words Included",
        contentTypes: "5 different content types",
        settings: "Advanced Settings",
        dashboard: "Intuitive Dashboard",
        content: "Unique and Better Content",
        specific: "",
        whatsapp: "Get Adley on  WhatsApp ",
    },
    {
        title: "Enterprise", 
        price: 5200,
        specific: "Need a solution perfectly catered for your niche",
    },
];

export default Plans