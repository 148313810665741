import React from 'react'
import Header from '../components/Header'
import PaymentForm from '../components/PaymentForm'
import '../css/payment.css'
const Payment = () => {
    return (
        <div className='page'>
            <Header />
            <h3 className='pay_now'>Upgrade Now</h3>
            <div className='payment_form'>
                <PaymentForm />
            </div>
        </div>
    )
}

export default Payment