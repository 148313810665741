import React, { useEffect, useState } from 'react'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '@mui/icons-material/Edit';

import "../../css/admindashboard.css"
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserAction, updateUserPlanAction } from '../../redux/actions';
import Select from 'react-select'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const options = [
  { value: 0, label: 'Free' },
  { value: 1600, label: 'Business' },
  { value: 4200, label: 'Gold' },
  { value: 10000, label: 'Enterprise' }
]

const getPlan = (name)=>{
  for (let i = 0; i < options.length; i++){
    if (options[i].label === name) return options[i]
  }
  return options[0]
}

const User = ({ user, index }) => {
  const dispatch = useDispatch()

  const [plan, selectedPlan] = useState(getPlan(user?.plan))
  const [popupDisplay, setPopupDisplay] = useState(false)
  const deleteUser = (gmail) => {
    let user = {
      gmail: gmail
    }
    dispatch(deleteUserAction(user))
  }
  const { loading: loadingDeleteUser, error: deleteError } = useSelector(state => state.deleteUser)
  const { loading: loadingUpdateUser, error: updateError } = useSelector(state => state.updateUserPlan)

  const updateUser = (gmail, plan) =>{
    let user = {
      gmail: gmail,
      newPlan: plan.label
    }
    dispatch(updateUserPlanAction(user))
  }
  useEffect(() => {
    if (loadingDeleteUser !== undefined && !loadingDeleteUser && !deleteError)
      window.location.reload()
  }, [loadingDeleteUser, deleteError])

  useEffect(() => {
    if (loadingUpdateUser !== undefined && !loadingUpdateUser && !updateError)
      window.location.reload()
  }, [loadingUpdateUser, updateError])

  return (
    <>
      <div className='single_user'>
        <p>{index}</p>
        <p>{user?.email?.substr(0, 40)}</p>
        <p>{user?.creationDate?.substr(0, 10)}</p>
        <p>{user?.plan}</p>
        <EditIcon className='d_none' onClick={() => setPopupDisplay(!popupDisplay)} />
        <DeleteForeverOutlinedIcon onClick={() => deleteUser(user?.email)} />
      </div>
      <div className={popupDisplay ? 'update_user_popup' : "popup_none"}>
        <div className='update_user_section'>
          <div className='cancel_popup'>
            <CancelOutlinedIcon onClick = {()=>setPopupDisplay(!popupDisplay)}/>
          </div>
          <div className="select_option_price">
            <Select options={options} defaultValue={options[0]} onChange={(e) => selectedPlan(e)} />
          </div>
          <div className='update_user_btn'>
            <button className='submit_btn' onClick={()=>updateUser(user?.email, plan)}>Update</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default User