import { Route, Routes } from "react-router-dom"
import "./App.css"
import Footer from "./components/Footer"
import Board from "./pages/Board"
import Plan from "./pages/Plan"
import ForgetPassword from "./pages/ForgetPassword"
import Payment from "./pages/Payment"
import ResetPassword from "./pages/ResetPassword"
import Signin from "./pages/Signin"
import Signup from "./pages/Signup"
import Verification from "./pages/Verification"
import Dashboard from "./pages/Admin/Dashboard"
import Billing from "./pages/Billing"
import Checkout from "./pages/Checkout"
import StartTrial from "./pages/StartTrial"

import TagManager from "react-gtm-module"
import { useEffect } from "react"
function App() {
  const tagManagerArgs = {
    gtmId: 'G-GB2NJ2K5Y2'
  }
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    window.dataLayer.push({
      event: 'homepage_view'
    });
  }, [])
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Board />} />
        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/dashboard" element={<Board />} />
        {/* <Route exact path="/profile" element={<Profile />} /> */}
        <Route exact path="/plan" element={<Plan />} />
        <Route exact path="/pay" element={<Payment />} />
        <Route exact path="/:userId/verify/:token" element={<Verification />} />
        <Route exact path="/forget" element={<ForgetPassword />} />
        <Route exact path="/user/:id/reset/:token" element={<ResetPassword />} />

        <Route exact path="/admin/dashboard" element={<Dashboard />} />

        <Route exact path="/billing" element={<Billing />} />
        <Route exact path="/checkout/:plan" element={<Checkout />} />

        <Route exact path="/freetrial" element={<StartTrial />} />

      </Routes>
      <Footer />
    </div>
  )
}

export default App
