export const USER_REGISTER_REQUEST = "user_register_request"
export const USER_REGISTER_SUCCESS = "user_register_success"
export const USER_REGISTER_FAIL = "user_register_fail"

export const USER_PROFILE_UPDATE_REQUEST = "user_profile_update_request"
export const USER_PROFILE_UPDATE_SUCCESS = "user_profile_update_success"
export const USER_PROFILE_UPDATE_FAIL = "user_profile_update_fail"

export const USER_LOGIN_REQUEST = "user_login_request"
export const USER_LOGIN_SUCCESS = "user_login_success"
export const USER_LOGIN_FAIL = "user_login_fail"

export const USER_PROFILE_REQUEST = "user_profile_request"
export const USER_PROFILE_SUCCESS = "user_profile_success"
export const USER_PROFILE_FAIL = "user_profile_fail"

export const USER_PLAN_REQUEST = "user_plan_request"
export const USER_PLAN_SUCCESS = "user_plan_success"
export const USER_PLAN_FAIL = "user_plan_fail"

export const USER_LOGOUT_REQUEST = "user_logout_request"
export const USER_LOGOUT_SUCCESS = "user_logout_success"
export const USER_LOGOUT_FAIL = "user_logout_fail"

export const WRITE_CONTENT_REQUEST = "write_content_request"
export const WRITE_CONTENT_SUCCESS = "write_content_success"
export const WRITE_CONTENT_FAIL = "write_content_fail"

export const FETCH_USERS_REQUEST = "fetch_users_request"
export const FETCH_USERS_SUCCESS = "fetch_users_success"
export const FETCH_USERS_FAIL = "fetch_users_fail"

export const DELETE_USER_REQUEST = "delete_user_request"
export const DELETE_USER_SUCCESS = "delete_user_success"
export const DELETE_USER_FAIL = "delete_user_fail"

export const UPDATE_USER_PLAN_REQUEST = "update_user_plan_request"
export const UPDATE_USER_PLAN_SUCCESS = "update_user_plan_success"
export const UPDATE_USER_PLAN_FAIL = "update_user_plan_fail"

export const CLEAR_ERRORS = "clear_errors"

export const BASE = getBaseUrl()

function getBaseUrl(){
    return process.env.REACT_APP_BASE_URL
}