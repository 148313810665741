import { motion } from "framer-motion";
import box from "../assets/list.png";

const SinglePlanCard = ({ plan, userPlan, viewOther, startTrial }) => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
                opacity: 1,
                scale: 1,
                transition: {
                    type: "keyframes",
                    duration: 1,
                    bounce: 1,
                    delay: 0.1,
                },
            }
            } className={viewOther ? 'single_plan_card view_page_plan' : 'single_plan_card'}>
            {plan?.title !== "Enterprise" ?
                <div className="price_features_list selected_plan_payment plan_card_info">
                    <h4 className="plan_card_title">{plan?.title}</h4>
                    <p style={{ marginTop: "20px", color: "white", fontSize: "18px", fontWeight: "600" }}>$1 for 3 days then</p>
                    <p className="plan_card_price">$ {plan?.price / 100}</p>
                    <div>
                        <img src={box} alt={plan?.title} />
                        <p>{plan?.words}</p>
                    </div>
                    <div>
                        <img src={box} alt={plan?.title} />
                        <p>{plan?.contentTypes}</p>
                    </div>
                    <div>
                        <img src={box} alt={plan?.title} />
                        <p>{plan?.settings}</p>
                    </div>
                    <div>
                        <img src={box} alt={plan?.title} />
                        <p>{plan?.dashboard}</p>
                    </div>
                    <div>
                        <img src={box} alt={plan?.title} />
                        <p>{plan?.content}</p>
                    </div>
                    {plan?.whatsapp && <div>
                        <img src={box} alt={plan?.title} />
                        <p>{plan?.whatsapp}</p>
                    </div>}
                </div> :
                <div className="price_features_list selected_plan_payment plan_card_info">
                    <h4 className="plan_card_title">{plan?.title}</h4>
                    <p style={{ marginTop: "20px", color: "white", fontSize: "18px", fontWeight: "600" }}>$1 for 3 days then</p>
                    <p className="plan_card_price">${plan?.price / 100}</p>
                    <div>
                        <p>{plan?.specific}</p>
                    </div>
                </div>
            }
            <div className="choose_plan_button">
                {viewOther !== true ?
                    <button className={userPlan === plan.title ? "submit_btn disabled_btn" : "submit_btn"} onClick={() => {
                        window.fbq('track', 'Purchase', {
                            plan: plan.title,
                            price: plan.price,
                        });
                        window.dataLayer.push({
                            event: 'checkout_redirect',
                            eventProps: {
                                label: plan.title,
                                action: "checkout_page_visited",
                            }
                        });
                        window.location.replace("/checkout/" + plan.title)
                    }} disabled={userPlan === plan.title}>{userPlan === plan.title ? "Subscribed" : startTrial === true ? "Start Free Trial" : "Choose Plan"}</button>
                    // :<button className="submit_btn" onClick={()=>window.location.replace("/billing")} disabled = {userPlan === plan.title}>View all plans</button>}
                    : <></>}
            </div>
        </motion.div>
    )
}

export default SinglePlanCard