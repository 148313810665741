import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "react-redux"
import Store from "./redux/Store"
import AlertTemplate from "react-alert-template-basic"
import { transitions, positions, Provider as AlertProvider } from "react-alert"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import { PAYPAL_LIVE_CLIENT_ID } from "./redux/constants"

const root = ReactDOM.createRoot(document.getElementById("root"))
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
}
const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_LIVE_CLIENT_ID,
  components: "buttons",
  currency: "USD",
  intent: "subscription",
  vault: true
};
root.render(
  // <React.StrictMode>
  <Router>
    <AlertProvider template={AlertTemplate} {...options}>
      <PayPalScriptProvider options={initialOptions}>
        <Provider store={Store}>
          <App />
        </Provider>
      </PayPalScriptProvider>
    </AlertProvider>
  </Router>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
