import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUserAction } from "../redux/actions";
import { motion } from "framer-motion";
import { useAlert } from "react-alert"
import { Alert } from '@mui/material'


export const SigninCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { ref, inView } = useInView({ threshold: 0.3 });
  const alert = useAlert();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [open, setOpen] = useState(false);

  function ValidateEmail(mail) {
    if ((/[^\s]*@[a-z0-9.-]*/i.test(mail))) {
      return true
    }
    alert.error("You have entered an invalid email address!");
    return false;
  }
  const { isAuthenticated, loading, error } = useSelector(
    (state) => state.loggedinUser
  );
  useEffect(() => {
    if (loading !== undefined) {
      if (!loading && isAuthenticated) {
        navigate("/dashboard")
        // window.location.replace("/");
      }
      if (!loading && error) {
        setOpen(true);
      }
    }
  }, [loading, isAuthenticated, error, navigate]);
  const submitData = (e) => {
    e.preventDefault();
    const userData = {
      password,
      email,
    };
    if (ValidateEmail(email)) {
      dispatch(loginUserAction(userData));
    }
  };
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0 }}
      animate={
        inView && {
          opacity: 1,
          scale: 1,
          transition: {
            type: "keyframes",
            duration: 1,
            bounce: 1,
            delay: 0.1,
          },
        }
      }
      className="registeration_card"
    >
      {open && !loading && error ? <Alert variant="filled" severity="error" onClose={() => { setOpen(false) }}>{error}</Alert> : <></>}
      <h3>Sign In</h3>
      <form className="signup_form" onSubmit={(e) => submitData(e)}>
        <input
          type="email"
          placeholder="Email"
          required={true}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          required={true}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="submit_btn fency_btn" disabled={loading ? true : false}>
          {loading ? "loading" : "Sign In"}
        </button>
        <div className="options_">
          <Link to="/forget" ><p>Forget Password</p> </Link>
          <p>
            Not already member <Link to="/signup">Signup Now</Link>
          </p>
        </div>
      </form>
    </motion.div>
  );
};
