import React, { useEffect } from "react";
import "../css/home.css";
import pic from "../assets/aidely (2).png";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUserAction, userProfileAction } from "../redux/actions";
import CloseIcon from '@mui/icons-material/Close';

const Header = ({ tryFree }) => {
  const dispatch = useDispatch();

  const { isAuthenticated, loading } = useSelector((state) => state.userProfile);
  const { load, user } = useSelector((state) => state.logoutUser);

  useEffect(() => {
    dispatch(userProfileAction());
  }, [dispatch]);

  useEffect(() => {
    if (load !== undefined && !load && user === null) {
      window.location.reload()
    }
  }, [load, user]);


  const logoutUser = () => {
    dispatch(logoutUserAction);
  }
  const [display, setDisplay] = useState(false);

  return (
    <div>
      <nav className="canvas header">
        <Link to="/">
          <div className="logo_section">
            <img src={pic} alt="adley.ai" />
            <p>ADLEY</p>
          </div>
        </Link>
        {/* <ul>
          <Link to="/">
            <li>Home</li>
          </Link>
          <a href="/#features_section"><li>Features</li></a>
          <a href="/#support_section"><li>Support</li></a>
          <a href="/#price_section"><li>Pricing</li></a>
          <a href="/#business_section"><li>Business</li></a>
        </ul> */}
        <div className="nav_end">
          <div>
            {loading !== undefined && !loading && isAuthenticated ? (
              <div
                onClick={logoutUser}
              >
                <p className="sselected_option">Log Out</p>
              </div>
            ) : (
              <Link to="/signin">

                <p className="sselected_option">Sign In</p>
              </Link>
            )}
          </div>
          <div className='try_free_btn' >{loading !== undefined && !loading && isAuthenticated ? <Link to={(user !== null && user?.type && user?.type === "admin") ? "/admin/dashboard" : "/"}><p>Dashboard</p></Link> : <Link to="/signup"><p>Try Now</p></Link>}</div>
        </div>
      </nav>
      <nav className="small_navbar canvas">
        <div className="sm-nav-header">
          <Link to="/">
            <div className="logo_section">
              <img src={pic} alt="adley.ai" />
              <p>ADLEY</p>
            </div>
          </Link>
          {!display ? (
            <div className="display_btn">
              <MenuIcon onClick={() => setDisplay(!display)} />
            </div>
          ) : (
            <div className="display_btn">
              <CloseIcon onClick={() => setDisplay(!display)} />
            </div>
          )}
        </div>
        {display && (
          <ul className={`header-resp ${display ? "run" : "close"}`}>
            <div className="nav_end">
            <div>
            {loading !== undefined && !loading && isAuthenticated ? (
              <div
                onClick={logoutUser}
              >
                <p className="sselected_option">Log Out</p>
              </div>
            ) : (
              <Link to="/signin">

                <p className="sselected_option">Sign In</p>
              </Link>
            )}
          </div>
              <div className='try_free_btn' >{loading !== undefined && !loading && isAuthenticated ? <Link to="/"><p>Dashboard</p></Link> : <Link to="/signup"><p>Try Now</p></Link>}</div>
            </div>
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Header;
