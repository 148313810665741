import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import UndoIcon from "@mui/icons-material/Undo";
import OpenWithTwoToneIcon from "@mui/icons-material/OpenWithTwoTone";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getContentAction } from "../redux/actions";
import { Alert } from "@mui/material";
import Loader from "./Loader";
import { BASE } from "../redux/constants";
import axios from "axios";
import PromptBar from "./PromptBar";
import { useAlert } from "react-alert";

const Write = ({
  mode,
  changeWordCount,
  changeRes,
  planError,
  changePlanError,
  title,
  changeTitle,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert()

  // const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [responseText, setResponseText] = useState("");

  const [isCopied, setIsCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingArticle, setLoadingArticle] = useState(false)

  // prompt bar
  const [language, setLanguage] = useState("English")
  const [keywords, setKeywords] = useState("")
  const [audience, setAudience] = useState("")
  const [result, setResult] = useState("")
  const [toneVoice, setToneVoice] = useState("creative")
  const [creativity, setCreativity] = useState("creative")

  const { load, content, isReceived, words, error, status } = useSelector(
    (state) => state.content
  );
  const { loading, isAuthenticated } = useSelector(
    (state) => state.userProfile
  );

  const writeArticle = () => {
    if (mode === "Article") {
      writeMainArticle();
    } else {
      let reqData = {
        title,
        description,
        mode,
        language,
        toneVoice: toneVoice,
        creativity: creativity,
        audience,
        keywords
      };
      dispatch(getContentAction(reqData));
    }
  };
  const writeMainArticle = async () => {
    setLoadingArticle(true);
    let LINK = BASE + `article/main/write`;
    const config = {
      headers: { "content-Type": "application/json" },
      withCredentials: true,
    };
    const data = {
      title,
      description,
      mode,
      language,
      toneVoice: toneVoice,
      creativity: creativity,
      audience,
      keywords
    };
    try {
      let res = await axios.post(LINK, data, config);
      setLoadingArticle(false);
      setDescription(res.data.data)
      changeWordCount(res.data.words);
    } catch (error) {
      setLoadingArticle(false);
      alert.error("Error Occured " + error?.response?.data?.message)
    }
  };
  useEffect(() => {
    if (!load && isReceived) {
      setResponseText(content);
      changeWordCount(words);
      changeRes(true);
      setDescription(content);
    }
    if (!load && error && status === 410) {
      changePlanError(true);
    }
  }, [load, isReceived, content, words, error, status]);

  useEffect(() => {
    if (loading !== undefined) {
      if (!loading) {
        if (!isAuthenticated) navigate("/signin");
      }
    }
  }, [isAuthenticated, navigate, loading]);

  useEffect(() => {
    clearInputs();
  }, [mode]);
  const clearInputs = () => {
    setDescription("");
    setResponseText("");
    changeTitle("");
    changeWordCount(0);
  };

  const onCopy = () => {
    setIsCopied(true);
    setOpen(true);
  };

  return (
    <div className="content_section">
      {isCopied && open && (
        <div className="text_copied">
          <Alert
            variant="filled"
            severity="success"
            onClose={() => {
              setOpen(false);
            }}
          >
            Text Copied
          </Alert>
        </div>
      )}
      <div className="content_section_nav">
        <div className="content_section_links">
          <div onClick={clearInputs}>
            <UndoIcon />
            <p>Undo</p>
          </div>
          <div>
            <CopyToClipboard onCopy={onCopy} text={responseText}>
              <div>
                <ContentCopyIcon />
                <p>Copy</p>
              </div>
            </CopyToClipboard>
          </div>
          <div>
            <OpenWithTwoToneIcon />
            <p>Expand</p>
          </div>
        </div>
      </div>
      {!load && !loadingArticle && (
        <div className={!planError ? `content_section_actual` : `d_none`}>
          <PromptBar
            setLanguage={setLanguage}
            audience={audience}
            setAudience={setAudience}
            result={result}
            setResult={setResult}
            keywords={keywords}
            setKeywords={setKeywords}
            setCreativity={setCreativity}
            setToneVoice={setToneVoice}
          />
          <div className="insert_title_bar">
            <input
              placeholder="Title"
              value={title}
              //onChange={(e) => setTitle(e.target.value)}
              onChange={(e) => changeTitle(e.target.value)}
              className="content_section_actual_input"
            />
            <button
              className="try_free_btn fency_btn write_btn"
              onClick={writeArticle}
              disabled={loading || load || planError || loadingArticle ? true : false}
            >
              {!loading && !load && !loadingArticle ? "Write" : "Analyzing"}
            </button>
          </div>
          <textarea
            placeholder="Type a sentence and click on write to complete"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="content_section_actual_textarea"
          />
        </div>
      )}
      {(load || loadingArticle) && <Loader />}
      {!load && !loadingArticle && (
        <div className="written_content">
          {!load && isReceived ? planError ? (
            <div className="plan_error">
              <p>{error}</p>
              <Link to="/pay">
                <button className="try_free_btn fency_btn">Upgrade plan</button>
              </Link>
            </div>
          ) : <></> : (
            <div className="plan_error">
              <p>{error}</p>
              <p>Error Occured. Try again</p>
            </div>
          )}
        </div>
      )}
      <div className="write_section_"></div>
    </div>
  );
};

export default Write;
