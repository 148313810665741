import React, { useEffect, useState } from "react";
import "../css/loader.css"

const states = [
  "Analyzing",
  "Generating",
  "Writing",
  "Fetching"
]

const services = [
  "Writing tools",
  "Business writing tools",
  "Generate video Ideas",
  "Write personal Bio",
  "Get Product Reviews"
]

const Loader = () => {
  const [information, setInformation] = useState(states[0])
  const [service, setService] = useState(services[0])
  const [counter, setCounter] = useState(0)
  const [servicesCounter, setServiceCounter] = useState(0)

  useEffect(() => {
    let timer = setTimeout(() => {
      setInformation(states[counter])
      if (counter < states.length - 1) setCounter(counter => counter + 1)
      else setCounter(0)
    }, 3500)
    return () => {
      clearTimeout(timer)
    }
  }, [counter, information])

  useEffect(() => {
    let timer = setTimeout(() => {
      setService(services[servicesCounter])
      if (servicesCounter < services.length - 1) setServiceCounter(servicesCounter => servicesCounter + 1)
      else setServiceCounter(0)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [servicesCounter, service])


  return (
    <div className="center custom_loader">
      <div className="our_services">
        <p>ADLEY.AI provides <br /><span>{"> " + service}</span></p>
      </div>
      <div className="col-sm-6 text-center">
        <p>We are preparing article for you! <br /> <span className={counter%2 === 0 ? "c_green": ""}>{information}</span></p>
        <div className="loader2"></div>
      </div>
    </div>
  );
};

export default Loader;
