import React, { useEffect, useState } from "react";
import "../css/dashboard.css";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Header from "../components/Header";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import MainDB from "../components/MainDB";
import Write from "../components/Write";
import { userProfileAction } from "../redux/actions";
import Person3Icon from '@mui/icons-material/Person3';
import ProfileCard from "../components/ProfileCard";
import PlanCard from "../components/PlanCard";

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [mobileView, setMobileView] = useState(false)
    const [wordCount, setWordCount] = useState(0);
    const [planError, setPlanError] = useState(false);
    const [res, setRes] = useState(false)
    const { load, remainingWords } = useSelector(
        (state) => state.content
    );
    const { loading, rw, user, isAuthenticated, trialStatus } = useSelector(
        (state) => state.userProfile
    );

    const [finaluser, setFinalUser] = useState(user);

    useEffect(() => {
        if (loading !== undefined) {
            if (!loading) {
                if (!isAuthenticated) navigate("/signin");
                if (trialStatus !== undefined) {
                    if (trialStatus !== "permanent") navigate("/freetrial")
                }
            }
        }
    }, [isAuthenticated, navigate, loading, trialStatus]);

    useEffect(() => {
        dispatch(userProfileAction())
    }, [dispatch]);

    useEffect(() => {
        setFinalUser(user)
    }, [user]);

    const [classification, setClassification] = useState("All")
    const [contentType, setContentType] = useState("Article")
    const [selectedTab, setSelectedTab] = useState(1)
    const [title, setTitle] = useState("");

    const changeTitle = (c) => {
        setTitle(c)
    }
    const userProfile = () => {
        navigate('/profile');
    }
    const userPlan = () => {
        navigate('/plan');
    }
    const changeClassification = (c) => {
        setClassification(c)
    }
    const changePlanError = (c) => {
        setPlanError(c)
    }
    const changeRes = (c) => {
        setRes(c)
    }
    const changeContentType = (c) => {
        setContentType(c)
    }
    const changeWordCount = (c) => {
        setWordCount(c)
    }
    const moveTabs = (i) => {
        setTitle("")
        setSelectedTab(i)
    }
    return (
        <div className="page">
            <Header />
            {!loading && trialStatus === "permanent" ?
                <>
                    <div className="dashboard_">
                        <div className={mobileView ? "b_black mobile_article_info" : "mobile_article_info"}>
                            <div className={!mobileView ? "d_none" : "pd_20"}>
                                <div className="profile_icon">
                                    <Person3Icon />
                                    <p>{finaluser.firstName + "  " + finaluser.lastName}</p>
                                </div>
                                <div className={selectedTab === 1 ? "db_links_combo db_links_combo_selected" : "db_links_combo"} onClick={() => moveTabs(1)}>
                                    <HomeOutlinedIcon /> <p>Home</p>
                                </div>

                                <div className={selectedTab === 2 ? "db_links_combo db_links_combo_selected" : "db_links_combo"} onClick={() => moveTabs(2)}>
                                    <CreateOutlinedIcon /> Write
                                </div>

                                <div className={selectedTab === 3 ? "db_links_combo db_links_combo_selected" : "db_links_combo"} onClick={() => moveTabs(3)}>
                                    <Person2OutlinedIcon /> Profile
                                </div>
                                <div className="db_links_combo selected_type_" onClick={() => moveTabs(1)}>
                                    {contentType}
                                </div>
                                <div className="words_information">
                                    <p>Word Count: <span>{loading ? 0 : wordCount}</span> </p>
                                    <p>Remaining words: <span>{!planError ? loading ? "loading" : !res ? rw : load ? "loading" : remainingWords : 0}</span></p>
                                </div>
                            </div>
                            <div onClick={() => setMobileView(!mobileView)} className="mobile_details_view">{mobileView ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}</div>
                        </div>
                        <div className="article_info">
                            <div className="profile_icon">
                                <Person3Icon />
                                <p>{finaluser.firstName + "  " + finaluser.lastName}</p>
                            </div>
                            {/* <p>User: <span>{user.firstName + "  " + user.lastName}</span></p> */}
                            {/* <p>Email: <span>{user.email}</span></p> */}
                            <div className={selectedTab === 1 ? "db_links_combo db_links_combo_selected" : "db_links_combo"} onClick={() => moveTabs(1)}>
                                <HomeOutlinedIcon /> <p>Home</p>
                            </div>

                            <div className={selectedTab === 2 ? "db_links_combo db_links_combo_selected" : "db_links_combo"} onClick={() => moveTabs(2)}>
                                <CreateOutlinedIcon /> Write
                            </div>

                            <div className={selectedTab === 3 ? "db_links_combo db_links_combo_selected" : "db_links_combo"} onClick={() => moveTabs(3)}>
                                <Person2OutlinedIcon /> Profile
                            </div>

                            <div className={selectedTab === 4 ? "db_links_combo db_links_combo_selected" : "db_links_combo"} onClick={() => moveTabs(4)}>
                                <AttachMoneyOutlinedIcon /> Plan
                            </div>


                            <div className="db_links_combo selected_type_" onClick={() => moveTabs(1)}>
                                {contentType}
                            </div>
                            <div className="words_information">
                                <p>Word Count: <span>{loading ? 0 : wordCount}</span> </p>
                                <p>Remaining words: <span>{!planError ? loading ? "loading" : !res ? rw : load ? "loading" : remainingWords : 0}</span></p>
                            </div>
                        </div>
                        {selectedTab === 1 ?
                            <MainDB
                                changeClassification={changeClassification}
                                changeContentType={changeContentType}
                                classification={classification}
                                contentType={contentType}
                                moveTabs={moveTabs}
                            /> :
                            selectedTab === 2 ?
                                <Write
                                    classification={classification}
                                    mode={contentType}
                                    changeWordCount={changeWordCount}
                                    wordCount={wordCount}
                                    changeRes={changeRes}
                                    planError={planError}
                                    changePlanError={changePlanError}
                                    title={title}
                                    changeTitle={changeTitle}
                                /> :
                                selectedTab === 3 ?
                                    <ProfileCard finaluser={finaluser} setFinalUser={setFinalUser} />
                                    :
                                    <PlanCard finaluser={finaluser} setFinalUser={setFinalUser} writtenWords={rw} />
                        }
                    </div>
                </> :
                <div className="main_loader">
                    <div className="loader2"> </div>
                </div>
            }
        </div >
    );
};

export default Dashboard;
