export const contentTypes = [
  {
    value: "Article",
    label: "Article",
    type: "Writing tools",
    description:
      "A tool for creating high-quality articles with improved sentence structure and optimized keywords.",
  },
  {
    value: "Story",
    label: "Story",
    type: "Writing tools",
    description:
      "A software that assists writers in creating captivating stories by offering features",
  },
  {
    value: "Description",
    label: "Description",
    description:
      "A software that helps writers craft compelling product and service descriptions by offering features like tone analysis, sentence structure suggestions, and keyword optimization.",
  },
  {
    value: "Amazon Product Description",
    label: "Amazon Product Description",
    type: "Business",
    description:
      "A software that assists in writing effective product descriptions for Amazon, with features like keyword optimization, competitor analysis, and sales-oriented language suggestions.",
  },
  {
    value: "Product Reviews",
    label: "Product Reviews",
    type: "Marketing",
    description:
      "helps writers create compelling and informative product reviews, with features like tone analysis, keyword optimization, and language suggestions to make the review more engaging and persuasive.",
  },
  {
    value: "Personal Bio",
    label: "Personal Bio",
    type: "Social media",
    description:
      "assists individuals in creating an engaging and impactful personal bio by offering features such as tone analysis, language suggestions, and structure templates.",
  },
  {
    value: "Product names",
    label: "Product Names",
    type: "Marketing",
    description:
      "Product Name Writing Tool - A software that helps businesses find unique, memorable, and SEO-friendly names for their products, with features such as keyword analysis, competitor research, and naming suggestions.",
  },
  {
    value: "Pros and cons",
    label: "Pros And Cons",
    type: "Miscellaneous",
    description:
      "Pros and Cons Writing Tool - A software that helps writers evaluate the benefits and drawbacks of a particular topic or product by providing a structured template for organizing pros and cons, and offering suggestions for language and tone.",
  },
  {
    value: "Video Ideas",
    label: "Video Ideas",
    type: "Business",
    description:
      "Video Ideas Writing Tool - A software that helps content creators generate and organize video ideas, with features such as keyword analysis, trend analysis, and suggestions for topics that align with audience interests and goals.",
  },
  {
    value: "Welcome Email",
    label: "Welcome Email",
    type: "Writing tools",
    description:
      "Welcome Email Writing Tool - A software that assists in writing engaging and effective welcome emails for new subscribers, customers, or employees, with features such as tone analysis, language suggestions, and template library.",
  },
  {
    value: "Social media post",
    label: "Social Media post",
    type: "Marketing",
    description:
      "helps individuals and businesses create compelling and engaging social media posts, with features like tone analysis, keyword optimization, and language suggestions to increase engagement and reach.",
  },
  {
    value: "Blog Ideas",
    label: "Blog Ideas",
    type: "Writing tools",
    description:
      "helps writers generate and organize blog post ideas, with features such as keyword analysis, trend analysis, and suggestions for topics that align with audience interests and goals.",
  },
  {
    value: "Blog Intro",
    label: "Blog Intro",
    type: "Writing tools",
    description:
      "assists in writing captivating and impactful introductions for blog posts, with features such as tone analysis, language suggestions, and structure templates to engage and hook the reader.",
  },
  {
    value: "Blog Outline",
    label: "Blog Outline",
    type: "Writing tools",
    description:
      "Blog Outline Writing Tool - A software that helps writers structure and organize their blog post content, with features like template library, topic brainstorming, and section organization to ensure a clear and effective flow of information.",
  },
]

export const contentClassification = [
  { value: "All", label: "All" },
  { value: "Business", label: "Business" },
  { value: "Marketing", label: "Marketing" },
  { value: "Social media", label: "Social media" },
  { value: "Writing tools", label: "Writing tools" },
  { value: "Miscellaneous", label: "Miscellaneous" },
]
