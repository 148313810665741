import React, { useState } from 'react'
import { contentClassification, contentTypes } from "../redux/contentTypes";

const MainDB = ({ changeClassification, changeContentType, classification, contentType, moveTabs }) => {

    const [searchTerm, setSearchTerm] = useState("")
    const [types, setTypes] = useState(contentTypes)

    return (
        <div className="content_section">
            <div className="search_bar">
                <input placeholder="Search here" value={searchTerm} onChange={(e) => {
                    setSearchTerm(e.target.value)
                }} />
            </div>
            <div className="content_types_nav">
                {contentClassification.map(c => {
                    return <div key={c?.value} onClick={() => changeClassification(c?.label)} className={classification === c?.label ? "content_types_nav_selected" : ""}> {c?.label} </div>
                })}
            </div>
            <div className="content_types">
                {
                    types.filter(name => name?.label?.includes(searchTerm)).map(cType => {
                        return (classification === cType?.type || classification === "All") &&
                            <div key={cType?.value} onClick={
                                () => {
                                    changeContentType(cType?.label)
                                    moveTabs(2)
                                }
                            } className={contentType === cType?.label ? "content_types_selected" : ""}>
                                {cType?.value}
                                <p>{cType?.description}</p>
                            </div>
                    })
                }
            </div>
            
        </div >
    )
}

export default MainDB