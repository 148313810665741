import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Header from "../components/Header"
import SinglePlanCard from "../components/SinglePlanCard"
import Plans from "../utility/Plans"

import "../css/checkout.css"
import Subscription from "../components/Subscription"
const Checkout = () => {
    const params = useParams()
    const navigate = useNavigate()

    const plan = Plans.filter((p) => { return p.title === params.plan })

    const { loading, isAuthenticated, user } = useSelector(
        (state) => state.userProfile
    );

    useEffect(() => {
        if (loading !== undefined) {
            if (!loading) {
                if (!isAuthenticated) navigate("/signin");
            }
        }
    }, [isAuthenticated, loading, navigate]);

    useEffect(() => {
        if (!params.plan) navigate("/billing")
    }, [params, navigate])

    return (
        <div className="page">
            <Header />
            {
                loading !== undefined && !loading ?
                    <div className="checkout_page">
                        <div className="checkout_form">
                            <div className="checkout_form_plan">
                                <SinglePlanCard plan={plan[0]} userPlan={user?.plan} viewOther = {true} />
                            </div>
                            <div className="checkout_form_payment_methods">
                                <Subscription plan={plan[0]} user = {user}/>
                            </div>
                        </div>
                    </div>
                    : <div className="main_loader">
                        <div className="loader2"> </div>
                    </div>
            }
        </div>
    )
}

export default Checkout