import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PLAN_FAIL,
  USER_PLAN_REQUEST,
  USER_PLAN_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  CLEAR_ERRORS,
  WRITE_CONTENT_REQUEST,
  WRITE_CONTENT_SUCCESS,
  WRITE_CONTENT_FAIL,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_PLAN_REQUEST,
  UPDATE_USER_PLAN_SUCCESS,
  UPDATE_USER_PLAN_FAIL,
} from "./constants"

export const RegisterNewUser = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
      }
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      }
    case USER_REGISTER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
        user: {},
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}

export const UserProfileUpdate = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
      }
    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      }
    case USER_PROFILE_UPDATE_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
        user: {},
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}

export const LoginUser = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
      }
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        user: action.payload,
        message: action.payload.message,
      }
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
        user: {},
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}
export const LogoutUser = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_LOGOUT_REQUEST:
      return {
        load: true,
        isAuthenticated: false,
        user: {},
      }
    case USER_LOGOUT_SUCCESS:
      return {
        load: false,
        isAuthenticated: false,
        user: null,
      }
    case USER_LOGOUT_FAIL:
      return {
        load: false,
        isAuthenticated: false,
        message: action.payload,
        user: {},
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}
export const UserProfile = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
      }
    case USER_PROFILE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        user: action.payload.user,
        rw: action.payload.remainingWords,
        trialStatus: action.payload.trialStatus,
      }
    case USER_PROFILE_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        message: action.payload,
        user: {},
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}
export const UsernewPlan = (state = { plans: [] }, action) => {
  switch (action.type) {
    case USER_PLAN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        plans: [],
      }
    case USER_PLAN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        plans: action.payload.plans
      }
    case USER_PLAN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        message: action.payload,
        plans: [],
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}

export const getContent = (state = { cotent: {} }, action) => {
  switch (action.type) {
    case WRITE_CONTENT_REQUEST:
      return {
        load: true,
        isReceived: false,
        content: {},
        error: null,
        status: null,
      }
    case WRITE_CONTENT_SUCCESS:
      return {
        load: false,
        isReceived: true,
        content: action.payload.data,
        words: action.payload.words,
        remainingWords: action.payload.remainingWords,
        error: null,
        status: null,
      }
    case WRITE_CONTENT_FAIL:
      return {
        load: false,
        isReceived: false,
        content: {},
        error: action.payload.error,
        status: action.payload.status,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}
export const fetchUsers = (state = { users: [{}] }, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        loading: true,
        uers: [],
        error: null,
      }
    case FETCH_USERS_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        error: null,
        records: action.payload.records
      }
    case FETCH_USERS_FAIL:
      return {
        loading: false,
        users: null,
        error: action.payload.error,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}

export const deleteUser = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return {
        loading: true,
        error: null,
      }
    case DELETE_USER_SUCCESS:
      return {
        loading: false,
        error: null,
        message: action.payload.message
      }
    case DELETE_USER_FAIL:
      return {
        loading: false,
        error: action.payload.message,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}

export const updateUserPlan = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_PLAN_REQUEST:
      return {
        loading: true,
        error: null,
      }
    case UPDATE_USER_PLAN_SUCCESS:
      return {
        loading: false,
        error: null,
      }
    case UPDATE_USER_PLAN_FAIL:
      return {
        loading: false,
        error: action.payload.message,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return { ...state }
  }
}